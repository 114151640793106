
import { mapGetters, mapMutations } from 'vuex'
import fieldLabelAndTooltip from '~/mixins/fieldLabelAndTooltip.js'

export default {
  name: 'Receive-Ballot',
  mixins: [fieldLabelAndTooltip],
  props: [
    'fieldName',
    'label',
    'value',
    'ballotReceiptOptions',
    'validations',
    'toolTipTitle',
    'toolTipContent',
    'dict'
  ],
  data () {
    return {
      baseClass: {
        'is-medium': true,
        'is-outlined': false,
        button: true
      },
      isOpen: false
    }
  },
  methods: {
    ...mapMutations('requests', ['update'])
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    recBallot: {
      get () {
        return this.getCurrent.recBallot
      },
      set (val) {
        this.update({ recBallot: this.recBallot === val ? null : val })
      }
    },
    email: function () {
      return this.value === 'email'
    },
    mail: function () {
      return this.value === 'mail'
    },
    fax: function () {
      return this.value === 'fax'
    },
    ...mapGetters('requests', ['getCurrent'])
  }
}
